import { defineStore } from 'pinia';
import { User, getCurrentUser } from "@/data/users";

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      currentUser: {} as User,
    }
  },
  getters: {
    hasCurrentUser(state): boolean {
      return !!(state.currentUser && state.currentUser.id);
    },
  },
  actions: {
    resetUser() {
      this.currentUser = {} as User;
    },
    async fetchUser() {
      this.currentUser = await getCurrentUser();
    },
  },
})
