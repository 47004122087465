import { defineStore } from 'pinia';
import { Mails, Mail, getMails, getMail, markAsRead, archive } from "@/data/mails";

export const useMailStore = defineStore('mail', {
  state: () => {
    return {
      mails: {} as Mails,
      currentMail: {} as Mail,
    }
  },
  getters: {
    hasMails(state): boolean {
      return !!(state.mails.data && state.mails.data.length);
    },
    hasCurrentMail(state): boolean {
      return !!(state.currentMail && state.currentMail.id);
    },
    isEmpty(state): boolean {
      return state.mails.data && !state.mails.data.length;
    },
    currentPage(state) {
      return state.mails.meta ? state.mails.meta.current_page : 1;
    },
    isOnLastPage(state): boolean {
      return state.mails.meta && state.mails.meta.current_page >= state.mails.meta.last_page;
    },
  },
  actions: {
    resetMails() {
      this.mails = {} as Mails;
    },
    resetCurrentMail() {
      this.currentMail = {} as Mail;
    },
    async fetchMails(page = 1) {
      if (this.mails.data) {
        const newMails = await getMails(page, 50);
        this.mails.data.push(...newMails.data);
        this.mails.links = newMails.links;
        this.mails.meta = newMails.meta;
      } else {
        this.mails = await getMails(page, 50);
      }
    },
    async fetchMail(id: number) {
      this.currentMail = await getMail(id);
    },
    async markMailAsRead(id: number) {
      const freshMail = await markAsRead(id);
      const index = this.mails.data.findIndex(mail => mail.id === id);
      if (index > -1) {
        this.$patch((state) => {
          state.mails.data[index] = freshMail;
        });
      }
    },
    async archiveMail(id: number) {
      await archive(id);
      const index = this.mails.data.findIndex(mail => mail.id === id);
      if (index > -1) {
        this.$patch((state) => {
          state.mails.data.splice(index, 1);
        });
      }
    }
  },
})
