import axios from 'axios';
import { Storage } from '@ionic/storage';
import router from '../router';

const store = new Storage();
store.create();

const apiUrl = process.env.VUE_APP_API_BASE || 'https://latermail.app/api';

axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.response.status === 401) {
        await store.remove('auth_token');
        router.push('/login');
    }

    return Promise.reject(error);
});

export interface User {
    id: number,
    name: string,
    email: string,
    mailbox_id: string,
    mailbox_address: string,
    created_at: string,
    updated_at: string,
}

export const getCurrentUser = async () : Promise<User> => {
    const token = await store.get('auth_token');
    const response = await axios.get(`${apiUrl}/users/me`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.data;
};
