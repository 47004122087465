import axios from 'axios';
import { Storage } from '@ionic/storage';
import router from '../router';

const store = new Storage();
store.create();

const apiUrl = process.env.VUE_APP_API_BASE || 'https://latermail.app/api';

axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.response.status === 401) {
        await store.remove('auth_token');
        router.push('/login');
    }

    return Promise.reject(error);
});

export interface Mails {
    data: Array<Mail>,
    links: PaginationLinks,
    meta: PaginationMeta,
}

export interface Mail {
    id: number
    user_id: number
    uuid: string
    from_email: string
    from_name: string
    subject: string
    text_content: string
    html_content: string
    read: boolean
    archived: boolean
    sent_at: string
    text_url: string,
    html_url: string,
}

export interface PaginationLinks {
    first: string,
    last: string,
    next: string,
    prev: string,
}

export interface PaginationMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export const getMails = async (page = 1, perPage = 15) : Promise<Mails> => {
    const token = await store.get('auth_token');
    const response = await axios.get(`${apiUrl}/mails`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        params: {
            page: page,
            per_page: perPage,
        }
    });

    return response.data;
};

export const getMail = async (id: number) : Promise<Mail> => {
    const token = await store.get('auth_token');
    const response = await axios.get(`${apiUrl}/mails/${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.data;
};

export const markAsRead = async (id: number) : Promise<Mail> => {
    const token = await store.get('auth_token');
    const response = await axios.post(`${apiUrl}/mails/${id}/read`, {}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.data;
};

export const archive = async (id: number) : Promise<Mail> => {
    const token = await store.get('auth_token');
    const response = await axios.post(`${apiUrl}/mails/${id}/archive`, {}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.data;
};
