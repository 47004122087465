import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { Storage } from '@ionic/storage';

const store = new Storage();
store.create();

const guestRoute: NavigationGuard = async function(to, from, next) {
    const token = await store.get('auth_token');
    if (token) {
        next({ name: 'Home' });
    } else {
        next();
    }
};

const privateRoute: NavigationGuard = async function(to, from, next) {
    const token = await store.get('auth_token');
    if (token) {
        next();
    } else {
        next({ name: 'Login' });
    }
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginPage.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/RegisterPage.vue'),
        beforeEnter: guestRoute
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/HomePage.vue'),
        beforeEnter: privateRoute
    },
    {
        path: '/mail/:id',
        component: () => import('../views/ViewMailPage.vue'),
        beforeEnter: privateRoute
    },
    {
        path: '/setup',
        component: () => import('../views/SetupPage.vue'),
        beforeEnter: privateRoute
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
